import React from 'react'
import Header from './inc/Header'
import Footer from './inc/Footer'
import Bio from './parts/Bio'
import Skills from './parts/Skills'

class Home extends React.Component {

    render() {
        return (
            <div className="section">
            <Header />
            <Bio />
            <Skills />
            <Footer />
            </div>
        )
    }

}

export default Home