import React from 'react'
import Logo from '../img/logo.svg'

class Header extends React.Component {

    componentDidMount () {
        const element =  document.querySelector('.logo')
        element.classList.add('animated', 'pulse')
    }

    render() {
        return (
            <header>
                <img className="logo" src={Logo} alt="Logo" />
            </header>
        )
    }

}

export default Header