import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToiletPaper, faTimes } from '@fortawesome/free-solid-svg-icons'

class Bio extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            togglePuzzle: ''
        }

    }

    open = () => {
        const toggle = this.state.toggleClass;
        console.log(toggle);
        if(toggle === 'opened') {
            this.setState({toggleClass: ''})
        } else {
            this.setState({toggleClass: 'opened'})
        }
        console.log(toggle);
    }

    render() {

        return (
            <div id="box" onClick={this.open}>
                Bio
                {
                    this.state.toggleClass ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faToiletPaper} />
                }
                <br />
                <div id="tab" className={this.state.toggleClass ? 'opened' : null}>
                    <p>
                        Hello, I'am Ignazio.
                        <br />
                        I'am an Italian web developer with +7 years of experience, I love to develop perfect solutions with a clean code and the newest technologies.
                    </p>
                </div>
            </div>
        )
    }
}

export default Bio