import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartbeat, faCode } from '@fortawesome/free-solid-svg-icons'
//import { faReact, faWordpress } from '@fortawesome/free-brands-svg-icons'

class Footer extends React.Component {

    render() {
        const wStyle = {
            margin: '0px 10px',
        }
        return (
            <footer>
                <div className="credits">
                <FontAwesomeIcon icon={faCode} /> <span style={wStyle}> with </span> <FontAwesomeIcon icon={faHeartbeat} />
                </div>
                {/*
                <div className="components">
                    <FontAwesomeIcon className="component" icon={faWordpress} />
                    <FontAwesomeIcon className="component" icon={faReact} />
                </div>
                */}
            </footer>
        )
    }
}

export default Footer