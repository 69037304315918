import React from 'react';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPuzzlePiece, faTimes } from '@fortawesome/free-solid-svg-icons'

class Skills extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            posts: [],
            loading: false,
            error: '',
            loaded: false,
            toggleClass: ''
        }
    }

    componentDidMount() {
        const url = 'https://ignaziomargiotta.it/wp/wp-json/wp/v2/skills?order=asc&per_page=100';
		this.setState( { loading: true }, () => {
			axios.get( url )
				.then( res => {
					if ( res.data.length ) {
						this.setState( { loading: false, loaded: true, posts: res.data } );
					} else {
						this.setState( { loading: false, error: 'No Posts Found' } );
					}
				} )
				.catch( err => this.setState( { loading: false, error: err.response.data.message } ) );
		} )
    }
    
    open = () => {
        const toggle = this.state.toggleClass;
        console.log(toggle);
        if(toggle === 'opened') {
            this.setState({toggleClass: ''})
        } else {
            this.setState({toggleClass: 'opened'})
        }
        console.log(toggle);
    }

    render() {

        const {posts} = this.state;

        return(
            <div id="box" onClick={this.open}>
                Skills
                {
                    this.state.toggleClass ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faPuzzlePiece} />
                }
                <br />
                <div id="tab" className={this.state.toggleClass ? 'opened' : null}>
                {posts.length ? (
                    posts.map(post => (
                        <div className="skill" key={post.id}>
                            {post.title.rendered}
                        </div>
                ))
                ) : ''}
                </div>
            </div>
        )

    }

}

export default Skills;