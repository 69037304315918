import React from 'react';
import './style/Code.css';
import './style/Animate.css';
import Home from './Home'
import { Router } from "@reach/router"

class App extends React.Component {

  render(){
  return (
    <Router>
      <Home path="/" />
    </Router>
  );
}
}

export default App;
